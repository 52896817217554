import { render, staticRenderFns } from "./additional-guest-memory-overhead-ratio.vue?vue&type=template&id=5e0178be&"
import script from "./additional-guest-memory-overhead-ratio.vue?vue&type=script&lang=js&"
export * from "./additional-guest-memory-overhead-ratio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports